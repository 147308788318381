import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { State as StateClass } from 'vuex-class/lib/bindings';
import { steps } from '@/helpers/checkout';
import { State } from '@/store/models';
import { IdentificationRequestStatus } from '@/store/models/identificationRequest';
import { Payment, PaymentStatus } from '@/store/models/investment';

@Component({})

export default class CheckoutHeader extends Vue {
  steps = steps;
  IdentificationRequestStatus = IdentificationRequestStatus;
  PaymentStatus = PaymentStatus;

  @Prop({ default: -1 }) currentRouteIndex!: number;

  @StateClass user!: State['user'];
  @StateClass identificationRequest!: State['identificationRequest'];
  @Getter isInvestor!: boolean;
  @Getter getPaymentById!: Function;

  get payment(): Payment | undefined {
    return this.getPaymentById(this.$route.params.paymentId);
  }

  get paymentStatus(): PaymentStatus | undefined {
    switch (this.payment?.providerData.status) {
      case PaymentStatus.Paid:
        return PaymentStatus.Paid;
      case PaymentStatus.Open:
        return PaymentStatus.Open;
      case PaymentStatus.Expired: // fall through since they all amount to a failed payment
        return PaymentStatus.Expired;
      case PaymentStatus.Cancel:
        return PaymentStatus.Cancel;
      case PaymentStatus.Failed:
        return PaymentStatus.Failed;
      case PaymentStatus.Pending: // fall through since we don't support a method, where these statuses would be applicable
        return PaymentStatus.Pending;
      case PaymentStatus.Authorized:
        return PaymentStatus.Authorized;
      default:
        return undefined;
    }
  }

  get identificationStatus(): string {
    if (this.user && this.isInvestor) {
      return IdentificationRequestStatus.Approved;
    }

    const customIdent = this.identificationRequest;
    if (customIdent) {
      if (customIdent.status === IdentificationRequestStatus.Initial) {
        return 'pending';
      }
      if (customIdent.status === IdentificationRequestStatus.Rejected) {
        return IdentificationRequestStatus.Rejected;
      }
    }
    return IdentificationRequestStatus.Initial;
  }
}
